import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				OceanQuest Yachting
			</title>
			<meta name={"description"} content={"Наш різноманітний флот ідеально підходить для будь-якої прогулянки. Вирушайте з нами на унікальну втечу на хвилях."} />
			<meta property={"og:title"} content={"OceanQuest Yachting"} />
			<meta property={"og:description"} content={"Наш різноманітний флот ідеально підходить для будь-якої прогулянки. Вирушайте з нами на унікальну втечу на хвилях."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Ласкаво просимо до OceanQuest Yachting, вашого головного місця для чудових і доступних морських пригод.
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							Вирушайте з нами на унікальну втечу на хвилях.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Наш різноманітний флот ідеально підходить для будь-якої прогулянки.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					OceanQuest Yachting гарантує досконалий сервіс і пропонує вибір яхт, розроблених для вашого комфорту та безпеки.
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07:35:51.382Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-3.jpg?v=2024-06-12T07%3A35%3A51.382Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1599357191354-c92f2c9f8078?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Ми зосереджені на наданні індивідуальних вражень, які задовольнять як випадкових дослідників, так і завзятих мореплавців.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07:35:51.315Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-1.jpg?v=2024-06-12T07%3A35%3A51.315Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Вибирайте з нашого різноманітного флоту та подбайте про те, щоб ваша подорож була спокійною, мальовничою та справді вашою.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Чому обирають нас
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Завдяки суднам, які ідеально підходять як для інтимних зустрічей, так і для великих груп, кожен може насолодитися враженнями від плавання.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Надійний флот:{" "}
								<br />
							</Strong>
							Наші яхти проходять ретельне обслуговування, щоб гарантувати вашу безпеку та комфорт.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Індивідуальні подорожі:{" "}
								<br />
							</Strong>
							Створіть свою ідеальну подорож, будь то спокійне ранкове плавання чи жвавий вечір на воді.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Цінний досвід:{" "}
								<br />
							</Strong>
							Насолоджуйтеся престижем яхтингу з пакетами, які враховують будь-який бюджет.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07:35:51.333Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0988e2e8e00217cc745/images/yacht.jpeg?v=2024-06-12T07%3A35%3A51.333Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Ми гарантуємо, що ваш досвід катання на яхті буде легким і приємним. Наша команда віддана керуванню кожною деталлю від бронювання до висадки, гарантуючи безперебійну та задоволену подорож.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3" />
			<Override slot="link1" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});